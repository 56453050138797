import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, ConfigProvider } from "antd";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import ToggleButton from "../../Components/ToggleButton/ToggleButton";
import FormLayout from "../../Components/FormLayout/FormLayout";
import Loader from "../../Components/Loader/Loader";
import navData from "../../service/navigation";
import fetchApiData from "../../service/apiUtils";
import { sendPostRequest } from "../../service/apiUtils";
import loadash from "lodash";
import "./brakecontrol.css";

const BrakeControl = () => {
  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[6].class = true;

  const [loader, setLoader] = useState(false);
  const [selectedVins, setSelectedVins] = useState([]);
  const [brakeStatus, setBrakeStatus] = useState(null);

  const dispatch = useDispatch();
  const option = useSelector((state) => state.vin.vin);
  const availableVins = convertArray(option);
  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    return convertedArray;
  }

  const getVinBrakeStatus = (vin) => {
    const endpoint = `brakeStatus?vin=${vin}`;
    setLoader(true);

    fetchApiData(endpoint)
      .then((data) => {
        setLoader(false);
        // api response successful
        if (data.status >= 200 && data.status < 400) {
          setBrakeStatus(
            getBrakeStateFromSignal(parseInt(data.brakeStatus, 10))
          );
        } else {
          setBrakeStatus(null);
        }
      })
      .catch((error) => {
        setLoader(false);
        alert("Failed to fetch brake status for VIN.");
        console.error("Error:", error);
      });
  };

  const handleSelectedVins = async (e) => {
    setSelectedVins(e);
    getVinBrakeStatus(e);
  };

  const handleSubmit = async (e) => {
    e.nativeEvent.stopImmediatePropagation();
    if (selectedVins?.length === 0) {
      setLoader(false);
      alert("Please select a VIN!");
      return;
    }

    const bodydata = {
      vin: selectedVins,
      iBrakeConfig: getFlippedBrakeSignal(brakeStatus),
    };

    if (brakeStatus === null) {
      alert("Failed to fetch brake status of vehicle. Please try again later.");
      setLoader(false);
      return;
    }

    if (
      /* eslint-disable no-restricted-globals */
      confirm(
        "Are you sure you want to update brake configuration status for this vehicle?"
      )
      /* eslint-enable no-restricted-globals */
    ) {
      try {
        const url = "updateBrakeStatus";
        const message = await sendPostRequest(url, bodydata);
        setBrakeStatus(!brakeStatus);
        alert(message.message);
        return;
      } catch (error) {
        alert(error);
      } finally {
        setLoader(false);
        return;
      }
    } else {
      setLoader(false);
      return;
    }
  };

  // Returns value of signal when brake status is flipped
  const getFlippedBrakeSignal = (brakeStatus) => {
    // return 0000 (off) signal
    if (brakeStatus) {
      return 0;
      // return 1010 (on) signal
    } else {
      return 10;
    }
  };

  // Converts brake signal to enabled/disabled value
  const getBrakeStateFromSignal = (brakeSignal) => {
    if (brakeSignal === 10) {
      return true;
      // brakeSignal must be 0
    } else {
      return false;
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <div className="brake-form">
          <FormLayout heading="Brake Configuration">
            <div className="form-inputs">
              <p>
                Select VIN
                <span className="imp"> *</span>
              </p>
              <Select
                style={{ paddingLeft: "0" }}
                className="form-select"
                placeholder="Select VIN"
                showSearch={true}
                onChange={handleSelectedVins}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={availableVins}
                autoComplete="off"
              ></Select>
            </div>
            <div className="form-inputs">
              <p>
                Brake Status
                <span className="imp"> *</span>
              </p>
              <div className="formTglBtn">
                <ToggleButton status={brakeStatus} onClick={handleSubmit} />
              </div>
            </div>
          </FormLayout>
        </div>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default BrakeControl;
