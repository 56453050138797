import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import FormLayout from "../../../Components/FormLayout/FormLayout";
import Inputs from "../../../HOCs/BaseTextInput/BaseInput";
import Button from "../../../HOCs/BaseButton/BaseButton";
import { Select, ConfigProvider } from "antd";
import navData from "../../../service/navigation";
import loadash from "lodash";
import Loader from "../../../Components/Loader/Loader";
import { useDispatch } from "react-redux";
import { setCustomers } from "../../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData from "../../../service/apiUtils";
import { sendPostRequest } from "../../../service/apiUtils";
import statesData from "../../../assets/states";
import cityData from "../../../assets/cities";
import TextArea from "antd/es/input/TextArea";

const Register = () => {

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[1].class = true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [location, setLocation] = useState(null);
  const [country, setCountry] = useState(null);
  const [State, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zipCode, setZipcode] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(" ")
  const [address, setAddress] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");


  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/location/registerLocation");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  const [loader, setLoader] = useState(true);

  const [custOpts, setCustOpts] = useState([]);
  useEffect(() => {
    const endpoint = "customers";
    fetchApiData(endpoint)
      .then((data) => {
        let customerOptions = convertArray(data.customers);
        setCustOpts(customerOptions);
        dispatch(setCustomers(customerOptions));
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const zipCodeRegularExpression = /^[0-9]*$/;
  const handleZipcode = (value) => {
    if (!zipCodeRegularExpression.test(value.target.value)) {
      setZipCodeError("Zipcode can only have numbers.");
    } else {
      setZipCodeError("");
    }
    setZipcode(value.target.value);
  };

  const handleCustName = (value) => {
    setCustomerName(value);
  };

  const handleCountry = (value) => {
    setCountry(value);
  }

  const handleState = (value) => {
    setState(value);
  }

  const handleCity = (value) => {
    setCity(value);
  }


  const handleLocation = (e) => {
    const value = e.target.value;
    setLocation(value);
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    setAddress(value);
  };

  const handleSubmit = async () => {
    setLoader(true);

    if (zipCodeError) {
      alert("Zipcode can only be numbers!");
      setLoader(false);
      return;
    }

    const submitData = {
      custId: customerName,
      locName: location,
      country: country,
      state: State,
      city: city,
      zipcode: zipCode,
      actionName: "locationName",
      address: address,
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const response = await sendPostRequest("register", submitData);
      alert("Location registered successfully!");
      setLoader(false);
      window.location.reload(); 
    } catch (error) {
      console.error(error);
      alert("Could not register location. Check console for details.")
      setLoader(false);
    }
  };

  const generateCityOptions = (state) => {
    if (cityData[state]) {
      return cityData[state].map((city) => ({
        value: city,
        label: city,
      }));
    }
    return [];
  };

  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const options = generateCityOptions(State);
    setCityOptions(options);
  }, [State]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout heading="Register Location" formType="compressed">
          <div className="form-inputs">
            <p>
              Customer Name
              <span className="imp"> *</span>
            </p>
            <Select
              value={customerName}
              options={custOpts}
              onChange={handleCustName}
              placeholder="Customer Name"
              showSearch={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Location Name
              <span className="imp"> *</span>
            </p>
            <Inputs
              value={location}
              onChange={handleLocation}
              placeholder="Location Name"
            />
          </div>
          <div className="form-inputs">
            <p>Country
              <span className="imp"> *</span>
            </p>
            <Select
              placeholder="Country"
              value={country}
              onChange={handleCountry}
              options={[
                { value: "India", label: "India", selected: true },
              ]}
              autoComplete="off"
            />
          </div>
          <div className="form-inputs">
            <p>
              State
              <span className="imp"> *</span>
            </p>
            <Select
              value={State}
              showSearch={true}
              options={statesData}
              placeholder="Select State"
              onChange={handleState}
            />
          </div>
          <div className="form-inputs">
            <p>
              City
              <span className="imp"> *</span>
            </p>
            <Select
              value={city}
              showSearch={true}
              options={cityOptions}
              placeholder="Select City"
              onChange={handleCity}
            />
          </div>
          <div className="form-inputs">
            <p>
              Zipcode
              <span className="imp"> *</span>
            </p>
            <Inputs
              value={zipCode}
              onChange={handleZipcode}
              placeholder="Zipcode"
            />
            {zipCodeError && (
              <div style={{ color: "red" }}>{zipCodeError}</div>
            )}
          </div>
          <div className="form-inputs">
            <p>
              Customer Address
              <span className="imp"> *</span>
            </p>
            <TextArea
              value={address}
              onChange={handleAddress}
              placeholder="Address"
            />
          </div>
          <Button
            onClick={handleSubmit}
            btnName="Submit"
            className="sbt-btn"
          />
        </FormLayout>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default Register;
