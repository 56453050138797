import React, { useEffect, useState, Suspense } from "react";
import { useSelector } from "react-redux";
import SearchBar from "../../Components/LogsBody/SearchBar";
import "./log.css";
import fetchApiData from "../../service/apiUtils";
import Loader from "../../Components/Loader/Loader";
import Unavailable from "../../assets/unavailableData.png";
const LogBody = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [heartbeatData, setHeartBeatData] = useState([]);
  const [diagData, setDiagData] = useState([]);
  const [tableHeadingHeart, setTableHeadingHeart] = useState([]);
  const [tableHeading, setTableHeading] = useState([]);
  const [tableHeadingDiag, setTableHeadingDiag] = useState([]);
  const [eventLogsData,setEventLogsData]=useState([])
  const [funct, setFunct] = useState(null);
  const [date, setDate] = useState("");
  const [vin, setVin] = useState("");
  const [apiData, setApiData] = useState();
  const [customDate, setCustomDate] = useState(null);
  const [unavailableData, setUnAvailableData] = useState(true);
  const [loader, setLoader] = useState(false);
  
  const[selectedVin,setSelectedVin]=(useState(
    useSelector((state)=>state.eventLog.logVin)
  ))
  const loadData = async (endPoint) => {
    await fetchApiData(endPoint).then((data) => {
      setApiData(data);
      if (data.heartbeat.length !== 0 && data.diag.length !== 0) {
        setUnAvailableData(false);
        setHeartBeatData(data.heartbeat);
        setDiagData(data.diag);
        setTableHeading(Object.keys(data.heartbeat[0]));

        const diagHaed = Object.keys(data.diag[0]);
        const timeStampDiagIdx = diagHaed.indexOf("createdtimestamp");
        const updatedDiagData = swap(0, timeStampDiagIdx, diagHaed);
        const vnIdx = diagHaed.indexOf("vin");
        const diagDataIdx = swap(1, vnIdx, updatedDiagData);
        setTableHeadingDiag(diagDataIdx);


        const heartHead = Object.keys(data.heartbeat[0]);
        const timeStampHeartIdx = heartHead.indexOf("createdtimestamp");
        const updatedHeartData = swap(0, timeStampHeartIdx, heartHead);
        const vinIdx = heartHead.indexOf("vin");
        const heartDataIdx = swap(1, vinIdx, updatedHeartData);
        setTableHeadingHeart(heartDataIdx);
      } else setUnAvailableData(true);
      setLoader(false);
    });
  };
  const swap = (i, j, arr) => {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
    return arr;
  };  
  const showUnavailableData = () => {
    setUnAvailableData(true);
  };
  const handleCollapsed = (e) => {
    setCollapsed(e);
  };

  const handleFunct = (e) => {
      setFunct(e);
      if(e==="Event Logs"){
       fetchEventLogsData();
      }
     }  
  const handleDate = (e) => {
      setDate(e);
  };
  const handleVin = (e) => {
      setVin(e); 
  };

  const handleCustomDate = (e) => {
    setCustomDate(e);
  };
  const fetchEventLogsData=()=>{
    if (date && (selectedVin || vin) && funct) {
      let endPoint;
      setLoader(true);
      if (date === "date") {
        endPoint = `/eventlogs?action=${date}&date=${customDate}&vin=${vin}`;
      } else {
        endPoint = `/eventlogs?action=${date}&vin=${vin}`;
      }
      fetchApiData(endPoint)
        .then((data)=>{
          setEventLogsData(data.data)
          if(eventLogsData){
            if(data.data.length !==0){
              setUnAvailableData(false)
            }else{
              setUnAvailableData(true)
            }
          }else{
            setUnAvailableData(true)
          }
          setLoader(false)
        })
        .catch((error) => {
          setUnAvailableData(true);
          setLoader(false);
        });
    }
  }
  const fetchLogsData=()=>{
      let endPoint;
      setLoader(true);
      if (date === "date") {
        endPoint = `/logs?action=${date}&vin=${vin}&date=${customDate}`;
      } else {
        endPoint = `/logs?action=${date}&vin=${vin}`;
      }
      loadData(endPoint);
  }
  const handleSubmit = () => {
    if (funct === "Event Logs" && date && vin) {
      setTableHeadingDiag([])
      setTableHeadingHeart([])
      setHeartBeatData([])
      setDiagData([])
      fetchEventLogsData()
    } else if (date && vin && funct) {
      setEventLogsData([])
       fetchLogsData();
    } else {
      alert("All fields are mandatory");
    }
    setSelectedVin(null);
  }

  return (
    <div className="logbody-main">
      {loader && <Loader />}
      <div className="logbody-heading">
        <div
          style={{
            width: "90%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "start",
              fontWeight: "bolder",
              fontSize: "1.7rem",
            }}
          >
            Logs
          </div>
          <div
            style={{
              justifyContent: "end",
              display: "flex",
              width: "100%",
              textAlign: "end",
            }}
          >
            <div style={{ width: "100%" }}>
              Note: Log messages for the last{" "}
              {funct === "Diag" ? "10 mins" : "30 secs"} may not be indexed
            </div>
          </div>
        </div>
      </div>
      <div className="search-bar">
        <SearchBar
          isCollapsed={handleCollapsed}
          onFunctChange={handleFunct}
          onDateChange={handleDate}
          onVinChange={handleVin}
          onCustomDate={handleCustomDate}
          onSubmit={handleSubmit}
          showUnavailable={showUnavailableData}
          selectedVin={selectedVin}
          setSelectedVin={setSelectedVin}
        />
      </div>
      <div
        style={{
          height: collapsed ? "80%" : "65%",
          transition: "height 0.5s ease",
        }}
        className="log-details"
      >
          <div
            className="log-table"
            style={{
              background: "rgb(0,0,0,0.8)",
              width:"92%",
              height: "91%",
              borderRadius: "15px",
              overflow: "scroll",
            }}
          >
          {unavailableData && (
            <div className="logs-dashboard-main">
              <img src={Unavailable} width={70} alt="" />
              <label className="dash-heading">NO DATA AVAILABLE</label>
              <label className="dash-content">
                Please select all the feilds
                <br /> to view data
              </label>
            </div>
          )}
          {!unavailableData && (
            <table
              style={{
                color: "#fff",
                width: "100%",
                borderSpacing: "15px",
                height: "50%",
              }}
            >
              <colgroup>
                {funct === "HeartBeat" && heartbeatData &&
                  tableHeadingHeart &&
                  tableHeadingHeart.map((_, index) => (
                    <col key={index} style={{ width: "100" }} />
                  ))}
                {funct === "Diag" && diagData &&
                  tableHeadingDiag &&
                  tableHeadingDiag.map((_, index) => (
                    <col
                      key={index}
                      style={{ width: `${100 / tableHeadingDiag.length}%` }}
                    />
                  ))}
              </colgroup>
              <thead>
                <tr>
                  {funct === "HeartBeat" &&
                    tableHeadingHeart &&
                    tableHeadingHeart.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  {funct === "Diag" &&
                    tableHeadingDiag &&
                    tableHeadingDiag.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  {funct === "Event Logs" &&  eventLogsData && (
                    <>
                      <th>Vin Number</th>
                      <th>Event Logs</th>
                      <th>Timestamp</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody style={{ overflowY: "scroll", height: "50%" }}>
                {funct === "HeartBeat" &&
                  heartbeatData &&
                  heartbeatData.map((hData, index) => {
                    let temp = hData;
                    return (
                      <tr key={index}>
                        {tableHeadingHeart.map((data, dataIndex) => (
                          <td key={dataIndex} style={{ textAlign: "center" }}>
                            <div style={{ width: "10rem" }}>{temp[data]}</div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                {funct === "Diag" &&
                  diagData &&
                  diagData.map((hData, index) => {
                    let temp = hData;
                    return (
                      <tr key={index}>
                        {tableHeadingDiag.map((data, dataIndex) => (
                          <td key={dataIndex} style={{ textAlign: "center" }}>
                            <div style={{ width: "10rem" }}>{temp[data]}</div>
                          </td>
                        ))}
                      </tr>
                    );
                   })}
              {funct==="Event Logs" &&(
               eventLogsData &&
               eventLogsData.map((log,index)=>(
                <tr key={index}>
                  <td style={{color:"white"}}>{log.vin}</td>
                  <td style={{color:"white"}}>{log.description}</td>
                  <td style={{color:"white"}}>{log.createdTimestamp}</td>
                </tr>
               ))
              )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogBody;
