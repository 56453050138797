import React, { useEffect, useState } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import navData from "../../../service/navigation";
import loadash from "lodash";
import { useNavigate } from "react-router-dom";
import { sendPostRequest } from "../../../service/apiUtils";
import Inputs from "../../../HOCs/BaseTextInput/BaseInput";
import { Select, ConfigProvider } from "antd";
import Button from "../../../HOCs/BaseButton/BaseButton";
import Loader from "../../../Components/Loader/Loader";
import FormLayout from "../../../Components/FormLayout/FormLayout";

// import Loader
const Register = (props) => {

  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [name, setName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [vertical, setVertical] = useState(null);
  const [loader, setLoader] = useState(false)

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
  }

  const handleWebsite = (e) => {
    const value = e.target.value;
    setWebsite(value);
  }

  const handleSelect = (value) => {
    setVertical(value);
  };


  const handleSubmit = async () => {
    setLoader(true);

    const submitData = {
      name: name,
      website: website,
      vertical: vertical,
      actionName: "customerName",
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const response = await sendPostRequest("register", submitData);
      alert("Customer registered successfully!");
      setLoader(false);
      window.location.reload(); 
    } catch (error) {
      console.error(error);
      alert("Could not register customer. Check console for details.")
      setLoader(false);
    }
  };

  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/customer/register");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);
  let feilds = loadash.cloneDeep(navData);
  feilds[4].enable = true;
  feilds[4].class = true;
  feilds[4].subheading[0].class = true;

  const verticalOpts = [
    { value: "Quick Commerce", label: "Quick Commerce" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Automobile", label: "Automobile" },
    { value: "Manufacturing", label: "Manufacturing" },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {feilds.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout heading="Register Customer">
          <div className="form-inputs">
            <p>Name
              <span className="imp"> *</span>
            </p>
            <Inputs
              value={name}
              onChange={handleName}
              placeholder="Name"
            />
          </div>
          <div className="form-inputs">
            <p>Website
              <span className="imp"> *</span>
            </p>
            <Inputs
              value={website}
              onChange={handleWebsite}
              placeholder="Website"
            />
          </div>
          <div className="form-inputs">
            <p>Vertical
              <span className="imp"> *</span>
            </p>
            <Select
              value={vertical}
              options={verticalOpts}
              placeholder="Select Vertical"
              onChange={handleSelect}
            />
          </div>
          <Button
            onClick={handleSubmit}
            btnName="Submit"
            className="sbt-btn"
          />
        </FormLayout>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default Register;
