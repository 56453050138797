import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Space, DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import { setLog } from "../../redux/slices/eventLogsSlice";
import "./searchbar.css";
import refresh from "../../assets/refresh.png";
const { Option } = Select;

const SearchBar = ({
  isCollapsed,
  onFunctChange,
  onDateChange,
  onVinChange,
  onCustomDate,
  onSubmit,
  showUnavailable,
  setSelectedVin
}) => {
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false);
  const [funct, setFunct] = useState(null);
  const [date, setDate] = useState(null);
  const [vin, setVin] = useState(null);

  // for enabling the custom date feature
  const [isCustom, setCustom] = useState(false);
  const [customDate, setCustomDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [reversedDate, setReveversedDate] = useState(null);
  const option = useSelector((state) => state.vin.vin);
  function convertArrayToObject(arr) {
    return arr.map((item) => {
      return { value: item, label: item };
    });
  }
  const data = convertArrayToObject(option);
  const handleCustomDate = (e) => {
    setCustomDate(e);
    const time = e.$d;
    const temp = time
      .toLocaleDateString("en-GB", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      })
      .replace(/\//g, "-");
    const currentDate = convertDateFormat(temp);
    setFormattedDate(currentDate);
    setReveversedDate(reverseDateFormat(temp));
    onCustomDate(currentDate);
  };
  const toggleCollapse = () => {
    isCollapsed(!collapsed);
    setCollapsed(!collapsed);
  };

  const handleChange = (value) => {
    onFunctChange(value);
    setFunct(value);
  };

  const handleDate = (value) => {
    if (value == "date") {
      setCustom(true);
    } else {
      setCustom(false);
    }
    setDate(value);
    onDateChange(value);
  };

  const handleVIN = (value) => {
    setVin(value);
    onVinChange(value);
  };

  const handleReset = () => {
    setDate(null);
    setFunct(null);
    setVin(null);
    onFunctChange(null);
    onDateChange(null);
    onCustomDate(null);
    onVinChange(null);
    setSelectedVin(null)
    dispatch(setLog(null))


    if (isCustom) {
      setCustomDate(null);
    }
    showUnavailable();
  };
  const disabledDates = (current) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const handleSearch = () => {
    onSubmit();
  };

  const handleRefresh = () => {
    onSubmit();
  };

  function convertDateFormat(dateString) {
    var dateParts = dateString.split("-");
    var day = dateParts[0];
    var month = dateParts[1];
    var year = dateParts[2];

    var convertedDate = year + "-" + month + "-" + day;
    return convertedDate;
  }
  function reverseDateFormat(dateString) {
    var dateParts = dateString.split("-");
    var day = dateParts[0];
    var month = dateParts[1];
    var year = dateParts[2];

    var convertedDate = day + "-" + month + "-" + year;
    return convertedDate;
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            cellHoverWithRangeBg: "#e6f4ff10x",
            activeBg: "#1b3643",
            hoverBg: "#1b3643",
            colorBgContainer: "#1b3643"
          },
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "25%",
          height: collapsed ? "10%" : "75%",
          width: "90%",
          transition: "height 0.5s",
          backgroundColor: "rgb(0,0,0,0.8)",
          padding: "1rem",
          borderRadius: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            color: "white",
            top: collapsed ? "20%" : "3%",
            left: "2%",
            fontSize: "1.2rem",
            fontWeight: "400",
          }}
        >
          Filters
        </div>

        {collapsed && (
          <div
            className="coll-filter-name"
            onClick={() => {
              toggleCollapse();
            }}
          >
            {funct && (
              <div>
                <div style={{ opacity: "0.8" }}>Select Log Type: </div> {funct}
              </div>
            )}
            {date && (
              <div>
                <div style={{ opacity: "0.8" }}>Date/Time: </div>
                {date === "today" && "Today"}
                {date === "yesterday" && "Yesterday"}
                {date === "date" && "Custom Date"}
              </div>
            )}
            {isCustom && customDate && (
              <div>
                <div style={{ opacity: "0.8" }}>Date: </div>
                {reversedDate}
              </div>
            )}
            {vin && (
              <div>
                <div style={{ opacity: "0.8" }}>VIN: </div>
                {vin}
              </div>
            )}
          </div>
        )}
        {!collapsed && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingTop: "2%",
            }}
          >
            <div
              style={{
                gridTemplateColumns: isCustom
                  ? "repeat(4, 1fr)"
                  : "repeat(3, 1fr)",
              }}
              className="filter-option"
            >
              <div>
                <div>
                  Select Log Type <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <Select
                    style={{
                      width: "60%",
                    }}
                    placeholder="Select Function"
                    onChange={handleChange}
                    optionLabelProp="label"
                    value={funct}
                  >
                    <Option selected value="HeartBeat" label="HeartBeat">
                      <Space>
                        <span role="img" aria-label="HeartBeat">
                          HeartBeat
                        </span>
                      </Space>
                    </Option>
                    <Option value="Diag" label="Diag">
                      <Space>
                        <span role="img" aria-label="Diag">
                          Diagnostics
                        </span>
                      </Space>
                    </Option>
                    <Option value="Event Logs" label="Event Logs">
                      <Space>
                        <span role="img" aria-label="Event Logs">
                          Event Logs
                        </span>
                      </Space>
                    </Option>
                  </Select>
                </div>
              </div>
              <div>
                <div>
                  Date/Time <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <Select
                    style={{
                      width: "60%",
                    }}
                    placeholder="Select Duration"
                    onChange={handleDate}
                    value={date}
                    options={[
                      {
                        value: "today",
                        label: "Today",
                      },
                      {
                        value: "yesterday",
                        label: "Yesterday",
                      },
                      {
                        value: "date",
                        label: "Custom Date",
                      },
                    ]}
                  />
                </div>
              </div>
              {isCustom && (
                <div>
                  <div>
                    Date <span style={{ color: "red" }}>*</span>
                  </div>
                  <DatePicker
                    className="custom_date"
                    onChange={handleCustomDate}
                    value={customDate}
                    allowClear={false}
                    format={"DD-MM-YYYY"}
                    disabledDate={disabledDates}
                  />
                </div>
              )}
              <div>
                <div>
                  VIN <span style={{ color: "red" }}>*</span>
                </div>
                <div>
                  <Select
                    showSearch
                    style={{
                      width: "60%",
                    }}
                    value={vin}
                    onChange={handleVIN}
                    placeholder="Select VIN"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={data}
                  />
                </div>
              </div>
            </div>

            <div className="log-filter-buttons">
              <div className="reset_options" onClick={handleReset}>
                RESET
              </div>
              <>|</>
              <button onClick={handleSearch} className="log-filter-search">
                Search
              </button>
            </div>
          </div>
        )}

        <div
          className="hide_toggle"
          style={{
            position: collapsed ? "" : "absolute",
            top: collapsed ? "26%" : "3%",
            width: collapsed ? "10%" : "",
            fontSize: "1rem",
          }}
        >
          {collapsed ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2%",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={handleRefresh}
                className="refresh-logs"
              >
                <img src={refresh} alt="refresh" width={15} />
                Refresh
              </div>
              <>|</>
              <div onClick={toggleCollapse} style={{ cursor: "pointer" }}>
                Show
              </div>
            </div>
          ) : (
            <div onClick={toggleCollapse} style={{ cursor: "pointer" }}>
              Hide
            </div>
          )}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default SearchBar;
