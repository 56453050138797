import React, { useState, useEffect } from "react";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import FormLayout from "../../Components/FormLayout/FormLayout";

import Button from "../../HOCs/BaseButton/BaseButton";
import { Select, DatePicker, ConfigProvider, TimePicker } from "antd";
import { useDispatch } from "react-redux";
import navData from "../../service/navigation";
import loadash from "lodash";
import Loader from "../../Components/Loader/Loader";
import moment from "moment";
import { setCustomers } from "../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData, { sendPostRequest } from "../../service/apiUtils";

const Assign = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [authenticated, setauthenticated] = useState(null);
  const [loader, setLoader] = useState(true);
  const [deploymentDate, setDeploymentDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vin, setVin] = useState(null);
  const [vinOpts, setVinOpts] = useState([]);
  const [customerName, setCustomerName] = useState(null);
  const [location, setLocation] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/assign_customer");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  const [custOpts, setCustOpts] = useState([]);
  useEffect(() => {
    const endpoint = "customers";
    fetchApiData(endpoint)
      .then((data) => {
        let customerOptions = convertArray(data.customers);
        setCustOpts(customerOptions);
        dispatch(setCustomers(customerOptions));
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const [locOpts, setLocOpts] = useState([]);
  useEffect(() => {
    if (customerName) {
      setLocation(null);
      setLocOpts(null);
      setLoader(true);
      const endpoint = `locations?custId=${customerName}`;
      fetchApiData(endpoint)
        .then((data) => {
          if (data.customers) {
            const locationOptions = convertArray1(data.customers);
            setLocOpts(locationOptions);
          }
          setLoader(false);
        })
        .catch((error) => {
          alert(error);
          setLoader(false);
          console.error(error);
        });
    }
  }, [customerName]);

  function convertArray1(array) {
    const convertedArray1 = array.map((item) => {
      return {
        label: item.locName,
        value: item.ROWID,
      };
    });
    return convertedArray1;
  }

  useEffect(() => {
    const endpoint = "vehiclesList?actionName=assignVehicles";
    fetchApiData(endpoint)
      .then((data) => {
        let vehicleOptions = convertArray2(data.vins);
        setVinOpts(vehicleOptions);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Some Error!");
        setLoader(false);
      });
  }, []);

  function convertArray2(array) {
    if (!array) {
      return [];
    }
    const convertedArray2 = array.map((item) => {
      return {
        label: item.vin,
        value: item.ROWID,
      };
    });
    return convertedArray2;
  }

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[3].class = true;

  // Disabling future Date
  const disableEndDate = (currentDate) => {
    return currentDate > moment().startOf();
  };

  // Disable dates before today
  const disabledDate = (current, startDate) => {
    if (startDate) {
      return current && current < startDate;
    } else {
      return current && current < moment().startOf("day");
    }
  };

  const handleStartTime = (time, timeString) => {
    const selectedDate = moment(deploymentDate).format("YYYY-MM-DD");
    setStartDate(selectedDate);
    setStartTime(timeString);
  };

  const handleEndTime = (time, timeString) => {
    const selectedDate = moment(deploymentDate).format("YYYY-MM-DD");
    setEndDate(selectedDate);
    setEndTime(timeString);
  };

  const handleCustName = (value) => {
    setCustomerName(value);
  };

  const handleLocName = (value) => {
    setLocation(value);
  };

  const handleVin = (value) => {
    setVin(value);
  };

  const handleSubmit = async () => {
    setLoader(true);
  
    if (!customerName || !location || !startDate || !startTime || !vin) {
      alert("Fill all mandatory fields!");
      setLoader(false);
      return;
    }
  
    const submitData = {
      locationId: location,
      vehicleId: vin,
      startDate: startDate + " " + startTime,
      endDate: endDate ? endDate + " " + endTime : null,
      actionName: "assignVehicles"
    };
  
    try {
      const response = await sendPostRequest("tagvehicles", submitData);
      alert("Vehicle assigned to customer successfully!");
      setLoader(false);
      window.location.reload(); 
    } catch (error) {
      console.error(error);
      alert("Could not assign vehicle. Check console for details.");
      setLoader(false);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
            TimePicker: {
              cellHoverWithRangeBg: "#e6f4ff10x",
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
            DatePicker: {
              cellHoverWithRangeBg: "#e6f4ff10x",
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
              colorBgContainer: "#1b3643",
            },
          },
          token: {
            colorBorder: "transparent",
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout heading="Assign Vehicles">
          <div className="form-inputs">
            <p>
              Customer Name
              <span className="imp"> *</span>
            </p>
            <Select
              value={customerName}
              options={custOpts}
              onChange={handleCustName}
              placeholder="Customer Name"
              showSearch={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Customer Location
              <span className="imp"> *</span>
            </p>
            <Select
              value={location}
              options={locOpts}
              onChange={handleLocName}
              placeholder="Select Location"
              showSearch={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Start Date
              <span className="imp"> *</span>
            </p>
            <DatePicker
              allowClear={false}
              disabledDate={(current) => disabledDate(current, deploymentDate)}
              format="DD-MM-YYYY"
              onChange={(date, dateString) => {
                setDeploymentDate(date);
                setStartDate(dateString);
              }}
              placeholder="Select Start Date"
              placement="bottomLeft"
              style={{ width: "100%" }}
            />
          </div>
          <div className="form-inputs">
            <p>
              Start Time
              <span className="imp"> *</span>
            </p>
            <TimePicker
              allowClear={false}
              onChange={handleStartTime}
              placeholder="Select Start Time"
              style={{ width: "100%" }}
            />
          </div>
          <div className="form-inputs">
            <p>End Date</p>
            <DatePicker
              allowClear={false}
              disabledDate={(current) => disabledDate(current, deploymentDate)}
              format="DD-MM-YYYY"
              onChange={(date, dateString) => {
                setDeploymentDate(date);
                setEndDate(dateString);
              }}
              placeholder="Select End Date"
              placement="bottomLeft"
              style={{ width: "100%" }}
            />
          </div>
          <div className="form-inputs">
            <p>End Time</p>
            <TimePicker
              allowClear={false}
              onChange={handleEndTime}
              placeholder="Select End Time"
              style={{ width: "100%" }}
            />
          </div>
          <div className="form-inputs">
            <p>
              VIN Number
              <span className="imp"> *</span>
            </p>
            <Select
              value={vin}
              options={vinOpts}
              onChange={handleVin}
              placeholder="Select VIN Number"
              showSearch={true}
            />
          </div>
          <Button
            onClick={handleSubmit}
            btnName="Submit"
            className="sbt-btn"
          />
        </FormLayout>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default Assign;
