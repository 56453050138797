import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import Body from "../../Components/Body/Body";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import navData from "../../service/navigation";
import loadash from "lodash";

function DashboardV2() {
  const navigate = useNavigate();
  // window.onpopstate = () => {
  //   navigate("/app/dashboard");
  // };

  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/dashboard");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  let fields = loadash.cloneDeep(navData);
  fields[1].class = true;

  return (
    <>
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Body />
      <Footer />
    </>
  );
}

export default DashboardV2;
