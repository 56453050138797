import { Input, ConfigProvider } from "antd";

const Inputs = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            activeBg: "#1b3643",
            hoverBg: "#1b3643",
          },
        },
        token: {
          colorText: "white",
        },
      }}
    >
      <Input
        {...props}
      />
    </ConfigProvider>
  );
}

export default Inputs;