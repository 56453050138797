import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import FormLayout from "../../../Components/FormLayout/FormLayout";
import { Select, ConfigProvider } from "antd";
import Inputs from "../../../HOCs/BaseTextInput/BaseInput";
import Button from "../../../HOCs/BaseButton/BaseButton";
import navData from "../../../service/navigation";
import loadash from "lodash";
import Loader from "../../../Components/Loader/Loader";
import { useDispatch } from "react-redux";
import { setCustomers } from "../../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData from "../../../service/apiUtils";
import { sendPostRequest } from "../../../service/apiUtils";
import PasswordInputs from "../../../HOCs/BasePassInput/BasePasswordInput";

const LoginID = () => {

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[2].class = true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [location, setLocation] = useState(null);
  const [role, setRole] = useState("Admin");
  const [userName, setUserName] = useState(null);
  const [passWord, setPassWord] = useState(null);

  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/customer/create-login");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  const [loader, setLoader] = useState(true);
  const [custOpts, setCustOpts] = useState([]);
  useEffect(() => {
    const endpoint = "customers";
    fetchApiData(endpoint)
      .then((data) => {
        let customerOptions = convertArray(data.customers);
        setCustOpts(customerOptions);
        dispatch(setCustomers(customerOptions));
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const [locOpts, setLocOpts] = useState([]);
  useEffect(() => {
    if (customerName) {
      setLocation(null);
      setLocOpts(null);
      setLoader(true);
      const endpoint = `locations?custId=${customerName}`; // Endpoint to fetch
      fetchApiData(endpoint)
        .then((data) => {
          if (data.customers) {
            const locationOptions = convertArray1(data.customers);
            setLocOpts(locationOptions);
          }
          setLoader(false);
        })
        .catch((error) => {
          alert(error);
          setLoader(false);
          console.error(error);
        });
    }
  }
    , [customerName]);

  function convertArray1(array) {
    const convertedArray1 = array.map((item) => {
      return {
        label: item.locName,
        value: item.ROWID,
      };
    });
    return convertedArray1;
  }

  const handleCustName = (value) => {
    setCustomerName(value);
  };

  const handleLocName = (value) => {
    setLocation(value);
  };

  const handleRole = (e) => {
    const value = e.target.value;
    setRole(value);
  };

  const handleUserName = (e) => {
    const value = e.target.value;
    setUserName(value);
  };

  const handlePassWord = (e) => {
    const value = e.target.value;
    setPassWord(value);
  };

  const handleSubmit = async () => {
    setLoader(true);

    const submitData = {
      username: userName,
      password: passWord,
      locationId: location,
      role: "Admin",
      actionName: "customerSignUp",
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const response = await sendPostRequest("register", submitData);
      alert("Customer ID created successfully!");
      setLoader(false);
      window.location.reload(); 
    } catch (error) {
      console.error(error);
      alert("Could not register customer ID. Check console for details.")
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout heading="Register LoginID" formType="compressed">
          <div className="form-inputs">
            <p>
              Customer Name
              <span className="imp"> *</span>
            </p>
            <Select
              value={customerName}
              options={custOpts}
              onChange={handleCustName}
              placeholder="Customer Name"
              showSearch={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Customer Location
              <span className="imp"> *</span>
            </p>
            <Select
              value={location}
              options={locOpts}
              onChange={handleLocName}
              placeholder="Select Location"
              showSearch={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Role
              <span className="imp"> *</span>
            </p>
            <Inputs
              value="Admin"
              onChange={handleRole}
              placeholder="Admin"
              readOnly={true}
            // disabled={true}
            />
          </div>
          <div className="form-inputs">
            <p>
              Username
              <span className="imp"> *</span>
            </p>
            <Inputs
              value={userName}
              onChange={handleUserName}
              placeholder="Username"
            />
          </div>
          <div className="form-inputs">
            <p>
              Password
              <span className="imp"> *</span>
            </p>
            <PasswordInputs
              placeholder="Password"
              onChange={handlePassWord}
              className="pass-inp"
              name="password"
              value={passWord}
              type="password"
              attr="password"
            />
          </div>
          <Button
            onClick={handleSubmit}
            btnName="Submit"
            className="sbt-btn"
          />
        </FormLayout>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default LoginID;
