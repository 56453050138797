import { Input, ConfigProvider } from "antd";

const PasswordInputs = ({ ...props }) => {

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        activeBg: "#1b3643",
                        hoverBg: "#1b3643",
                    },
                },
                token: {
                    colorText: "white",
                },
            }}
        >
            <Input.Password
                {...props}
            />
        </ConfigProvider>
    )
}
export default PasswordInputs;