import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadash from "lodash";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import Loader from "../../Components/Loader/Loader";
import fetchApiData from "../../service/apiUtils";
import FormLayout from "../../Components/FormLayout/FormLayout";
import Inputs from "../../HOCs/BaseTextInput/BaseInput";
import { Select, ConfigProvider } from "antd";
import navData from "../../service/navigation";
import Button from "../../HOCs/BaseButton/BaseButton";
import { fetchAvailableVinNumber } from "../../service/apiUtils";
import { sendPostRequest } from "../../service/apiUtils";


const VinGenerator = () => {
  const [isVinGen, setIsVinGen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [availableVins, setAvailableVins] = useState([]);
  const [prodCode, setProdCode] = useState(null)
  const [vehicleStage, setVehicleStage] = useState(null)
  const [newVin, setNewVin] = useState(null)
  const [vin, setVin] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [updatedStage, setUpdatedStage] = useState(null);
  const [newId, setNewId] = useState(null);
  const [id, setId] = useState(null);
  const [vinOpts, setVinOpts] = useState([]);

  useEffect(() => {
    setLoader(true);
    const endpoint = "/availVinsByCategory"; // Endpoint to fetch
    fetchApiData(endpoint)
      .then((data) => {
        let available = convertArray(data.vins);
        setId(data.id)
        setAvailableVins(available);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => ({
      label: item.vin,
      value: item.ROWID,
    }));
    return convertedArray;
  }

  const handleVINNumber = (e) => {
    // setVin(e);
    if (e) {
      setLoader(true);
      const endpoint = `vehiclestage?rowid=${e}`; // Endpoint to fetch
      fetchApiData(endpoint)
        .then((data) => {
          setCurrentStage(data.currentStage);
          setLoader(false);
        })
        .catch((error) => {
          alert(error);
          setLoader(false);
          console.error(error);
        });
      // setBtnName(false);
    }
  };

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[5].class = true;

  const handleVINGen = () => {
    setIsVinGen(true);
  };

  const handleUpdateVin = () => {
    setIsVinGen(false);
  };

  const handleUpdateStage = (value) => {
    setUpdatedStage(value);
  };

  const handleNewVin = (e) => {
    const value = e.target.value;
    setNewVin(value);
  };

  const prodOpts = [
    { value: "MOPSNL", label: "MOPTro SNAIL" },
    { value: "MOPLTE", label: "MOPTro LITE" },
    { value: "MOPLTP", label: "MOPTro LITE +" },
    { value: "NXP400", label: "NXP400" },
    { value: "NXP500", label: "NXP500" },
    { value: "NXP750", label: "NXP750" },
  ];

  const stageOpts = [
    { value: "R&D", label: "R&D" },
    { value: "Production", label: "Production" },
  ];

  const UpdateStageOpts = [
    { value: "R&D", label: "R&D" },
    { value: "Production", label: "Production" },
    { value: "Inventory", label: "Inventory" },
    { value: "Sold", label: "Sold" },
    { value: "Rented", label: "Rented" },
  ];

  const handleProdCode = (value) => {
    setProdCode(value);
  }

  const handleGenerateVin = async () => {
    setLoader(true);
    if (prodCode && vehicleStage) {
      const endpoint = `generateVin?prodcode=${prodCode}`;
      try {
        const data = await fetchAvailableVinNumber(endpoint);
        setNewVin(data.newvin);
        setNewId(data.id)
        setLoader(false);
      } catch (error) {
        alert(error);
      }
    } else {
      setLoader(false);
      alert("prodCode or vehicleCategory is missing.");
    }
  };

  const handleVehicleStage = (value) => {
    setVehicleStage(value);
  }

  // const handleVin = (value) => {
  //   setVin(value);
  //   if (value) {
  //     setLoader(true);
  //     const endpoint = `vehiclestage?rowid=${value}`; // Endpoint to fetch
  //     fetchApiData(endpoint)
  //       .then((data) => {
  //         setCurrentStage(data.currentStage);
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         alert(error);
  //         setLoader(false);
  //         console.error(error);
  //       });
  //   }
  // };

  const handleVin = async (selectedVin) => {
    setVin(selectedVin); // Set the selected VIN

    if (!selectedVin) {
      console.error("No VIN selected");
      return;
    }

    try {
      setLoader(true);
      // Make a request to fetch the ID corresponding to the selected VIN
      const response = await fetch(`vehicles?id=${selectedVin}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch ID for VIN ${selectedVin}`);
      }
      const data = await response.json();
      if (!data.id) {
        throw new Error(`ID not found for VIN ${selectedVin}`);
      }
      const vinId = data.id;
      setNewId(vinId); // Set the ID corresponding to the selected VIN
    } catch (error) {
      console.error("Error fetching ID:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const endpoint = "vehicles";
    fetchApiData(endpoint)
      .then((data) => {
        let vehicleOptions = convertArray2(data.vins);
        setVinOpts(vehicleOptions);
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
        setLoader(false);
      });
  }, []);

  function convertArray2(array) {
    if (!array) {
      return [];
    }
    const convertedArray2 = array.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    return convertedArray2;
  }

  const handleVinGenSubmit = async () => {
    setLoader(true);

    const submitData = {
      newvin: newVin,
      prodCode: prodCode,
      vehicle_stage: vehicleStage,
      id: newId,
      actionName: "vinGenerator"
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const response = await sendPostRequest("registerVin", submitData);
      alert("VIN registered successfully!");
      setLoader(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert("Could not register customer. Check console for details.")
    } finally {
      setLoader(false);
    }
  }

  const handleStageSubmit = async () => {
    setLoader(true);

    const submitData = {
      vehicle_stage: updatedStage,
      id: vin,
      actionName: "updateVin"
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const response = await sendPostRequest("updateCategory", submitData);
      alert("Stage updated successfully!");
      setLoader(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert("Could not update stage. Check console for details.")
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout>
          <div className="vin-gen-heading">
            <div
              className="vin-gen-sub-heading"
              style={{
                opacity: `${isVinGen ? "1" : "0.8"}`,
                borderBottom: `${isVinGen ? "2px solid #00b7a8" : ""}`,
              }}
              onClick={handleVINGen}
            >
              VIN Generator
            </div>
            <div
              className="vin-gen-sub-heading"
              style={{
                opacity: `${!isVinGen ? "1" : "0.8"}`,
                borderBottom: `${!isVinGen ? "2px solid #00b7a8" : ""}`,
              }}
              onClick={handleUpdateVin}
            >
              Update Vehicle Stage
            </div>
          </div>
          {isVinGen ? (
            <>
              <div className="form-inputs">
                <p>
                  VEHICLE TYPE
                  <span className="imp"> *</span>
                </p>
                <Select
                  value={prodCode}
                  showSearch={true}
                  options={prodOpts}
                  placeholder="Select Product Type"
                  onChange={handleProdCode}
                />
              </div>
              <div className="form-inputs">
                <p>
                  VEHICLE STAGE
                  <span className="imp"> *</span>
                </p>
                <Select
                  value={vehicleStage}
                  showSearch={true}
                  options={stageOpts}
                  placeholder="Select Vehicle Stage"
                  onChange={handleVehicleStage}
                />
              </div>
              <div className="form-inputs">
                <p>
                  GENERATED VIN
                  <span className="imp"> *</span>
                </p>
                <Inputs
                  value={newVin}
                  onChange={handleNewVin}
                  placeholder="Generated VIN"
                  // disabled={true}
                  readOnly={true}
                />
                <Button
                  btnType="vinGen"
                  onClick={handleGenerateVin}
                  btnName="Generate Vin"
                  className="vin-gen-btn"
                />
              </div>
              <Button
                onClick={handleVinGenSubmit}
                btnName="Submit"
                className="sbt-btn"
              />
            </>
          ) : (
            <>
              <div
                style={
                  {
                    width: "100%",
                    paddingLeft: "20rem"
                  }
                }
                className="form-inputs">
                <p>
                  SELECT VIN
                  <span className="imp"> *</span>
                </p>
                <Select
                  value={vin}
                  options={vinOpts}
                  onChange={handleVin}
                  placeholder="Select VIN"
                  showSearch={true}
                />
              </div>
              <div
                style={
                  {
                    width: "100%",
                    paddingLeft: "20rem"
                  }
                }
                className="form-inputs">
                <p>
                  CURRENT STAGE
                  <span className="imp"> *</span>
                </p>
                <Inputs
                  value={currentStage}
                  placeholder="Current Stage"
                  readOnly={true}
                  disabled={true}
                />
              </div>
              <div
                style={
                  {
                    width: "100%",
                    paddingLeft: "20rem"
                  }
                }
                className="form-inputs">
                <p>
                  UPDATE STAGE
                  <span className="imp"> *</span>
                </p>
                <Select
                  value={updatedStage}
                  options={UpdateStageOpts}
                  onChange={handleUpdateStage}
                  placeholder="Update Stage"
                />
              </div>
              <Button
                btnType="submit"
                btnName="Submit"
                className="sbt-btn"
                onClick={handleStageSubmit}
              />
            </>
          )}
        </FormLayout>
        <Footer />
        <div className="vin-main">
          <h2 className="vin-heading">VIN Generator</h2>
          <div className="vin-body"></div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default VinGenerator;
