import React from "react";
import "./BaseButton.css";

const Button = ({ ...props }) => {
  return (
    <button {...props}>{props.btnName}</button>
  )
};

export default Button;
