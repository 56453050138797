import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setVin } from "../../redux/slices/vinReducer";
import { useDispatch, useSelector } from "react-redux";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import LiveDashboardBody from "../../Components/Body/LiveDashboardBody";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import navData from "../../service/navigation";
import loadash from "lodash";
import fetchApiData from "../../service/apiUtils";

function LiveDashboard() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  window.onpopstate = () => {
    navigate("/app/livedashboard");
  };

  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
      const fetchData = async () => {
        const url = "vehicles";
        try {
          const data = await fetchApiData(url);
          dispatch(setVin(data.vins));
        } catch (error) {
          alert(error);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/livedashboard");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  let fields = loadash.cloneDeep(navData);
  fields[0].class = true;

  return (
    <>
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <LiveDashboardBody/>
      <Footer />
    </>
  );
}

export default LiveDashboard;
