import React, { useState } from "react";
import "./ToggleButton.css"

const ToggleButton = (props) => {
    let status = "null";
    let btnClassName = "unselected"

    if (props.status === true) {
        status = "DISABLE";
        btnClassName = "tglBtnDisable"
    } else if (props.status === false){
        status = "ENABLE";
        btnClassName = "tglBtnEnable"
    }
    else {
        status = "ENABLE"
    }

    return (
        <button
            className={btnClassName}
            name='toggleBtn'
            type='button'
            onClick={(e) => props.onClick(e)}
            placeholder=''
            search='false'
            aria-multiselectable='false'
        >
            {`${status}`}
        </button>
    );
};

export default ToggleButton;
