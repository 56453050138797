import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import loadash from "lodash";
import moment from "moment";

import parameters from "../../assets/parameters";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import { getParameterUnit } from "../../assets/parameterunit";
import navData from "../../service/navigation";
import { sendPostRequest } from "../../service/apiUtils";
import Button from "../../HOCs/BaseButton/BaseButton";
import Loader from "../../Components/Loader/Loader";
import { DatePicker, Select, ConfigProvider } from "antd";
import FormLayout from "../../Components/FormLayout/FormLayout";

import "./ParameterReport.css";

const { RangePicker } = DatePicker;

const ParameterReport = () => {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [loader, setLoader] = useState(false);
  const loggedInUser = localStorage.getItem("authenticated");
  const [date, setDate] = useState(null);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [selectedVins, setSelectedVins] = useState([]);
  const [parametersData, setParametersData] = useState(null);

  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/parameter_report");
    } else {
      navigate("/app");
    }
  }, [authenticated]);

  const option = useSelector((state) => state.vin.vin);
  function convertArrayToObject(arr) {
    return arr.map((item) => {
      return { value: item, label: item };
    });
  }
  const availableVins = convertArrayToObject(option);
  let fields = loadash.cloneDeep(navData);
  fields[2].class = true;

  const dateStyle = {
    border: "none",
    paddingLeft: "0.5rem",
    backgroundColor: "#1B3643",
    color: "white",
    width: "22rem",
  };

  const handleParameterChange = (selectedParams) => {
    if (selectedParams.length > 5) {
      selectedParams = selectedParams.slice(0, 5);
    }
    setSelectedParameters(selectedParams);
  };

  //Disabling future Date
  const disableEndDate = (currentDate) => {
    return currentDate > moment().startOf();
  };

  const disabledHours = () => {
    const hours = [];
    const curHour = moment().hour();

    for (let i = curHour + 1; i <= 24; i++) {
      hours.push(i);
    }

    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    const currentMinute = moment().minute();
    const curHour = moment().hour();

    if (selectedHour === curHour) {
      for (let i = currentMinute; i <= 60; i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  // Disabling future times
  const disabledDateTime = () => ({
    disabledHours: () => disabledHours(),
    disabledMinutes: () => disabledMinutes(),
  });

  function getFormattedDateTime(data) {
    const year = data.$y.toString();
    const month = (data.$M + 1).toString().padStart(2, "0");
    const day = data.$D.toString().padStart(2, "0");
    const hours = data.$H.toString().padStart(2, "0");
    const minutes = data.$m.toString().padStart(2, "0");
    const seconds = data.$s.toString().padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const parseTimeRange = (newDate) => {
    const startDate = getFormattedDateTime(newDate[0]);
    const endDate = getFormattedDateTime(newDate[1]);
    setDate([startDate, endDate]);
  };

  const handleSubmit = async () => {
    setLoader(true);

    const submitData = {
      startTime: date?.[0],
      endTime: date?.[1],
      vin: selectedVins,
      parameters: selectedParameters,
    };

    for (const key in submitData) {
      const data = submitData[key];
      const isArray = Array.isArray(data);

      if (!data || (isArray && data.length === 0)) {
        alert("Fill all mandatory fields!");
        setLoader(false);
        return;
      }
    }

    try {
      const url = "parameters";
      const response = await sendPostRequest(url, submitData);

      if (response.parameterData) {
        setParametersData(response.parameterData);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: "#1b3643"
          },
          components: {
            Input: {
              activeBg: "#1b3643",
              hoverBg: "#1b3643",
            },
            DatePicker: {
              activeBg: "#1b3643",
              cellHoverWithRangeBg: "transparent",
            }
          },
        }}
      >
        {loader && <Loader />}
        <Head />
        <div style={{ width: "10%" }} className="sidebar">
          <ul className="list">
            {fields.map((field, idx) => (
              <SideBody
                key={idx}
                path={field.path}
                img={field.img}
                class={field.class}
                name={field.name}
                subheading={field.subheading}
                enable={field.enable}
              />
            ))}
          </ul>
        </div>
        <FormLayout heading="Vehicle Report" formType="wide">
          <div className="form-inputs-wide">
            <p>
              Select Parameters
              <span className="imp"> *</span>
            </p>
            <Select
              style={{ paddingLeft: "0" }}
              className="parameter-select"
              placeholder="Select Parameters"
              mode="multiple"
              options={parameters}
              value={selectedParameters}
              onChange={handleParameterChange}
            ></Select>
          </div>
          <div className="form-inputs-wide">
            <p>
              Select VIN
              <span className="imp"> *</span>
            </p>
            <Select
              style={{ paddingLeft: "0" }}
              className="parameter-select"
              placeholder="Select VIN"
              showSearch={true}
              onChange={setSelectedVins}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={availableVins}
              autoComplete="off"
            ></Select>
          </div>
          <div className="form-inputs-wide">
            <p>
              Select Start/End Date
              <span className="imp"> *</span>
            </p>
            <RangePicker
              disabledDate={disableEndDate}
              disabledTime={disabledDateTime}
              onChange={parseTimeRange}
              showTime={{ format: "HH:mm" }}
              style={dateStyle}
            />
          </div>
          <Button
            onClick={handleSubmit}
            btnName="Submit"
            className="sbt-btn"
          />
          {parametersData && (
            <div style={{ textAlign: "left" }}>
              <p className="parameters-report">Vehicle Report:</p>
              <div style={{ display: "flex" }}>
                {Object.keys(parametersData).map((parameter, index) => (
                  <div key={index}>
                    <p className="parameter">
                      {parameter} {getParameterUnit(parameter, parametersData)}
                    </p>
                    <p className="parameterdata">{parametersData[parameter]}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </FormLayout>
        <Footer />
      </ConfigProvider>
    </>
  );
};

export default ParameterReport;
