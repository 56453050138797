import React from "react";
import "./FormLayout.css"

const FormLayout = (props) => {
    const classNameBody = props.formType === 'wide' ? 'form-body-wide' : 'form-body';
    const classNameInput = props.formType === 'wide' ? 'form-inputs-wide' : 'form-inputs';

    return (
        <div className="main-form">
            <div className={classNameBody}>
                <div className="heading">
                    <h2>{props.heading}</h2>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default FormLayout;
