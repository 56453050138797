import React, { useEffect, useState } from "react";
import arrow from "../../assets/arrow.png";
import fetchApiData from "../../service/apiUtils";
import Loader from "../Loader/Loader";
import "./LiveDashboardBody.css";
import close from "../../assets/close.png"

import { DatePicker, Select } from "antd";

const LiveDashboardBody = () => {
  const [open, setOpen] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const [loader,setLoader]=useState(true)
  const [selectedCustomer,setSelectedCustomer]=useState()
  const [filteredData,setFilteredData]=useState([])
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedVin,setSelectedVin]=useState(null)
  const [eventLogsData,setEventLogsData]=useState([])
  const [selectedDate,setSelectedDate]=useState("today")
  const [selectedCustomDate,setSelectedCustomDate]=useState([])
  const [isCustom,setIsCustom]=useState(false)
  const [notAvailable,setNotAvailable]=useState(false)
  const [availableData,setAvailableData]=useState(false)
  const fetchVehicleData = async () => { //Vehicle API fetch???
    const endPoint = `dashboardData`;
    try 
    {
      const response = await fetchApiData(endPoint);
      if (response.status === 200) {
        // Sort the data by customer name in alphabetical order
        if(response.data){
          const sortedData = response.data.sort((a, b) =>
          a.customer.localeCompare(b.customer)
        );
        const uniqueCustomers = Array.from(new Set(sortedData.map(item => item.customer)));
        setCustomerOptions(["All Owners", ...uniqueCustomers]);
        setLiveData(sortedData);
        setLoader(false)
        }
        else{
          setAvailableData(true)
          setLoader(false)
        }
      } else if (response.status === 404) {
        setLiveData([]);
      }
    } catch (error) {
      alert(error);
    }
  };
  const filterData=()=>{
    if (selectedCustomer) {
          const filtered = liveData.filter(
            (item) => item.customer === selectedCustomer
          );
          setFilteredData(filtered);
          setLoader(false)
        }
        else {
          setFilteredData(liveData);
          setLoader(false);
        }
  }
  const handleEventLogs= (vinNumber)=>{
    setOpen(true)
    setNotAvailable(true)
    setSelectedVin(vinNumber)
  }

  const renderLiveDashboardData = (data) => {
    return data.map((element, index) => (
       <div className="live-report" key={index} style={{marginBottom:"2rem"}}>
        <div className="data-customer">{element.customer} </div>
        <div className="data-location">{element.location}</div>
        <div className="data-vinnumber" onClick={() => handleEventLogs(element.vin)}>{element.vin}</div>
        <div className={element.health === "OK" ? "ok" : "notOk"}> {element.health}</div>
        <div className="data-vin"> {element.liveStatus}</div>
        <div className="data-vin"> {element.IotConnection}</div>
        <div className="data-vin"> {element.pingedTime}</div>
        <div className="data-vin">{element.voltage}</div>
      </div>
    ));
  };
  const handleSelectedCustomer = (e) => {
    const selectedOption = e.target.value;
    setSelectedCustomer(selectedOption === "All Owners" ? "" : selectedOption);
    setLoader(true)
  };
  
  const handleSelectedDate = (value) => {
    if (value == "date") {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
    setSelectedDate(value)
  };
  useEffect(()=>{
    fetchVehicleData()
    const updateVehicleData = setInterval(fetchVehicleData, 30000)
    return () => clearInterval(updateVehicleData);
  },[selectedVin]);
  const handleCloseEventLogs=()=>{
    setOpen(false)
    setEventLogsData([])
    setSelectedDate("today")
    setIsCustom(false)
  }

  const handleSelectedCustomDate=(value)=>{
    setSelectedCustomDate(value)
  }
  return (
    <>
    {loader && <Loader/>}
      <div className={open ?"info-errorlogs":"info-div"}>
        <div className="info-heading" style={{fontWeight:"bold"}}>Live Dashboard</div>
        <div className="info-form">
          <div>
            <label style={{ marginLeft:"1rem", marginRight:"1rem",fontWeight:"bold"}}>Filter By Owner</label>
            <select onChange={handleSelectedCustomer} onClick={filterData} className="filter">
              {customerOptions.map((customer, index) => (
                <option style={{border:"none"}} key={index} value={customer}>
                  {customer}
                </option>
              ))}
            </select>
        </div>
        <div id="fixed_heading" className="body-report">
                <div style={{marginLeft:"1rem"}}>Owner</div>
                <div>Location</div>
                <div>VIN <br/>(select vin for event logs)</div>
                <div>Health</div>
                <div>Status</div>
                <div>IoT connection</div>
                <div>Last Pinged</div>
                <div>Last Pinged Voltage</div>
        </div>
          <div className="dashboard-table">
            {availableData && (
              <div style={{color:"white"}}>No Data Available</div>
            )}
            <div>
            {selectedCustomer ?(
                <div>
                    {renderLiveDashboardData(filteredData)}
                </div>
              ):
              <div style={{ marginBottom: "2rem" }}>
                {renderLiveDashboardData(liveData)}
              </div>}
            </div>
          </div>
        </div>
      </div>
      {open && (
          <div className="eventlogs-popup">
          <div className="close" style={{display:"flex"}}>
              <img className="cross" src={close} alt="" onClick={handleCloseEventLogs}/>
          </div>
          <div style={{display:"flex"}}>
          <Select
                  style={{
                    width: "30%",
                    marginRight:"2rem"
                  }}
                  value={selectedDate}
                  onChange={handleSelectedDate}
                  placeholder="Select Duration"
                  options={[
                    {
                      value: "today",
                      label: "Today",
                    },
                    {
                      value: "yesterday",
                      label: "Yesterday",
                    },
                    {
                      value: "date",
                      label: "Custom Date",
                    },
                  ]}
                />
                {isCustom && (
                  <DatePicker
                  style={{backgroundColor:"#1B3643",border:"none"}}
                  onChange={handleSelectedCustomDate}
                  allowClear={false}
                  format={"DD-MM-YYYY"}
                 />
                )}
                <button  className="eventlogs-submit">SUBMIT</button>
          </div>
          <div className="error-title" style={{color:'white',fontWeight:"bold"}}>{selectedVin}</div>
          <div className="table-list">
            <div className="eventlogs-heading">
              <div style={{color:"white",fontWeight:"bold",}}>Event Logs</div>
              <div style={{color:"white" ,fontWeight:"bold",marginRight:"2rem"}}>Timestamp</div>
            </div>
            <div>
            {eventLogsData &&
               eventLogsData.map((eventlog,index)=>(
                <div key={index}  className="eventlogs-data">
                  <div >{eventlog.description}</div>
                  <div>{eventlog.createdTimestamp}</div>
                </div>
               ))}
              {notAvailable &&(
                <div style={{color:"white",textAlign:"center",fontSize:"1.5rem",fontWeight:"bold"}}>Feature is coming soon...</div>
              )}
            </div>
          </div>
          </div>
        )}
    </>
  );
};

export default LiveDashboardBody;
